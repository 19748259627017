import * as bootstrap from "bootstrap"
import jquery from "jquery"
import validate from "jquery-validation"
import PdfMake from "pdfmake"
import DataTable from 'datatables.net-bs5'
import DataTableButtons from 'datatables.net-buttons-bs5'
import DataTableButtonsHTML from 'datatables.net-buttons/js/buttons.html5.js'
import Tags from "bootstrap5-tags";
import 'select2'
import Rails from "@rails/ujs"

import 'moment';
import 'bootstrap-daterangepicker/daterangepicker';
Rails.start()

window.jQuery = jquery;
window.$ = jquery;
window.pdfmake = PdfMake;

document.addEventListener('DOMContentLoaded', () => {

  var finalPaymentAmountField = document.getElementById('early_payment_request_final_payment_amount');
  var rejectionButton = document.getElementById('btn-epr-reject');
  var submitButton = document.getElementById('btn-epr-approve');
  // Attach event listeners to buttons
  rejectionButton && rejectionButton.addEventListener('click', function() {
    finalPaymentAmountField.removeAttribute('required');
  });
  
  submitButton && submitButton.addEventListener('click', function() {
    if (finalPaymentAmountField.value === '') {
      finalPaymentAmountField.setAttribute('required', 'required');
    }
  });

  $('.date-range-picker').daterangepicker({
    showDropdowns: true,
    autoUpdateInput: false,
    opens: 'center',
    locale: {
      format: 'DD/MM/YYYY',
    },
  });

  $('.date-range-picker').on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
  });

  $('.date-range-picker').on('apply.daterangepicker', function(ev, picker) {
    $('#invoice-page-filter-form').submit();
  });
});

window.po_calculate = function(id) {
  const temp = id.split("purchase_order_items_attributes_")[1].split("_quantity")[0]

  const quantity_id = id
  const price_id = "purchase_order_items_attributes_" + temp + "_price"
  const total_id = "purchase_order_items_attributes_" + temp + "_total_amount"

  let val_1 = $("#" + quantity_id).val();
  let val_2 = $("#" + price_id).val();

  let total = val_1 * val_2
  $("#" + total_id).val(total);
}

window.po_calculate_total = function(id) {
  const temp = id.split("purchase_order_items_attributes_")[1].split("_price")[0]

  const quantity_id = id
  const price_id = "purchase_order_items_attributes_" + temp + "_quantity"
  const total_id = "purchase_order_items_attributes_" + temp + "_total_amount"

  let val_1 = $("#" + quantity_id).val();
  let val_2 = $("#" + price_id).val();

  let total = val_1 * val_2
  $("#" + total_id).val(total);
}

window.do_calculate = function(id) {
  const temp = id.split("delivery_order_items_attributes_")[1].split("_quantity")[0]

  const quantity_id = id
  const price_id = "delivery_order_items_attributes_" + temp + "_price"
  const total_id = "delivery_order_items_attributes_" + temp + "_total_amount"

  let val_1 = $("#" + quantity_id).val();
  let val_2 = $("#" + price_id).val();

  let total = val_1 * val_2
  $("#" + total_id).val(total);
}

window.do_calculate_total = function(id) {
  const temp = id.split("delivery_order_items_attributes_")[1].split("_price")[0]

  const quantity_id = id
  const price_id = "delivery_order_items_attributes_" + temp + "_quantity"
  const total_id = "delivery_order_items_attributes_" + temp + "_total_amount"

  let val_1 = $("#" + quantity_id).val();
  let val_2 = $("#" + price_id).val();

  let total = val_1 * val_2
  $("#" + total_id).val(total);
}



window.invoice_calculate = function(id) {
  const temp = id.split("invoice_items_attributes_")[1].split("_quantity")[0]

  const quantity_id = id
  const price_id = "invoice_items_attributes_" + temp + "_price"
  const total_id = "invoice_items_attributes_" + temp + "_total_amount"

  let val_1 = $("#" + quantity_id).val();
  let val_2 = $("#" + price_id).val();

  let total = val_1 * val_2
  $("#" + total_id).val(total);
}

window.invoice_calculate_total = function(id) {
  const temp = id.split("invoice_items_attributes_")[1].split("_price")[0]

  const quantity_id = id
  const price_id = "invoice_items_attributes_" + temp + "_quantity"
  const total_id = "invoice_items_attributes_" + temp + "_total_amount"

  let val_1 = $("#" + quantity_id).val();
  let val_2 = $("#" + price_id).val();

  let total = val_1 * val_2
  $("#" + total_id).val(total);
}

$('.reference_document_type').on("change", function(){
  $(this).parents('.fields').find('.select2-selection__rendered').text('');
  $(this).parents('.fields').find('.select2-item').val('');
});

function select2(){
  $('.select2').select2({
    minimumInputLength: 2,
    placeholder: "Select an option",
    allowClear: true,
    ajax: {
      url: function () {
        return "/"+$(this).parents('.fields').find('.reference_document_type').attr("data-request")+"/fetch_ref_doc_number.json";
      },
      dataType: 'json',
      quietMillis: 250,
      data: function (params) {
        var ref_doc_type = $(this).parents('.fields').find('select').val();
        return {
          search: params.term,//search term
          pagelimit: 1, // page size
          page: params.page, // page number
          type: ref_doc_type,
        };
      },
      processResults: function (data, params) {
        params.page = params.page || 1;
        var more = (params.page * 10) < data.count;
        return {
          results: data.reference_document_numbers,
          pagination: {
            more: more
          }
        }
      }
    }
  });
}

function select2_select(){
  $('.select2').on('select2:select', function (e) {
    var number = $(e.currentTarget).val();
    var nextSelectId = $(this).parents('.fields').find('.select2-item')[0].id;
    var SelectId = $("#"+nextSelectId);
    var ref_doc_type = $(this).parents('.fields').find('select').val();
    var route = $(this).parents('.fields').find('.reference_document_type').attr("data-request");
    $.ajax({
      url: "/"+route+"/fetch_ref_doc_item_number",
      type: 'GET',
      data: { number: number, type: ref_doc_type },
      dataType: 'json',
      success: function(data) {
        SelectId.empty();
        SelectId.append($('<option>').text('Select an option').attr('value', ''));
        $.each(data, function(key, value) {
          SelectId.append($('<option>').text(`${value.item_number}`).attr('value', value.item_number));
        });
      }
    });
  });
}

window.addEventListener('DOMContentLoaded', () => {
  Tags.init();

  let sideBar = localStorage.getItem("sideBar")
  if (sideBar === "true" || sideBar === true) {
    $("#wrapper").addClass("toggled");
  } else {
    $("#wrapper").removeClass("toggled");
  }

  setTimeout(function() {
    $('.flash_notice').slideUp()
  }, 2000);

  $('.generate-report-btn').on('click', function (e) {
    setTimeout(function() {
      $('form')[0].reset()
      $('.generate-report-btn').prop('disabled', false);
    }, 1000);
  });

  $("input[type='date']").on('click', function() {
    this.showPicker()
  })

  $("input[type='submit']").click(function() {
    $("#invoice-amount-epr-details").val($("#invoice-amount-epr-details").val()?.replace(/,/g, ''))
    // $("#entity-total-amount").val($("#entity-total-amount").val()?.replace(/,/g, ''))
    // $(".total-amount").val($(".total-amount").val()?.replace(/,/g, ''))
    // $(".item-total-amount").val($(".item-total-amount").val()?.replace(/,/g, ''))
    // $(".price-quantity").val($(".price-quantity").val()?.replace(/,/g, ''))
  })
  
  $("#menu-toggle").click(function(e) {
    e.preventDefault();
    $("#wrapper").toggleClass("toggled");
    let sideBar = localStorage.getItem("sideBar")

    if (sideBar === "false" || sideBar === false) {
      sideBar = true
    } else {
      sideBar = false
    }
    localStorage.setItem("sideBar", sideBar)
  });

  select2();
  select2_select();

  $("form").validate({
    invalidHandler: function(form, validator) {
      $(".form_submit_button").prop("disabled", false);
      $('.generate-report-btn').prop('disabled', false);
    }
  });

$(document).ready(function() {
  var table = $("Table").DataTable({
    "pageLength": 100
  });

  table.on('page', function() {
    window.scrollTo(0, 0);
  });

  $('form').submit(function(event) {
    var startDate = new Date($('#start_date').val());
    var endDate = new Date($('#end_date').val());
    if (startDate > endDate) {
      alert('The start date must be less than to the end date.');
      event.preventDefault();
    }
  });

});

  $(".dropdown-toggles").on("click", function() {
    $(".dropdown-menu").removeClass("show");
    $(this).siblings(".dropdown-menu").toggleClass("show");
  });

  $(document).on("click", function(event) {
    if (!$(event.target).closest(".dropdown").length) {
      $(".dropdown-menu").removeClass("show");
    }
  });

  $('.payment-terms-triggerer').on("change", function() {
    var invoiceDateString = $("#invoice_invoice_date").val()
    var paymentDateString = $("#invoice_payment_date").val()
    var invoiceDate = new Date(invoiceDateString)
    var paymentDate = new Date(paymentDateString)

    if (invoiceDateString && paymentDateString) {
      if (paymentDate >= invoiceDate) {
        let difference = paymentDate.getTime() - invoiceDate.getTime();
        let differenceInDays = Math.ceil(difference / (1000 * 3600 * 24));
        $("#invoice_payment_days_one").val(differenceInDays)
      } else {
        alert('Payment Date is older than Invoice Date')
        $("#invoice_payment_days_one").val('')
      }
    }
  });  

  $('.form-check-input').on("change", function(){
    $('.form-check-input')[0].checked == true ? $('#container').removeClass('hide') : $('#container').addClass('hide')
  });

  $('.epr-notification-check').on("change", function(){
    $('.epr-notification-check:checked').length > 0 ? $('#container-epr-notification').removeClass('hide') : $('#container-epr-notification').addClass('hide')
  });

  $('.import-email-check').on("change", function(){
    $('.import-email-check:checked').length > 0 ? $('#container-import-email').removeClass('hide') : $('#container-import-email').addClass('hide')
  });

  $('.org-email-check').on("change", function(event){
    event.target.checked ? $(`#org-email-check-${event.target.id}-container`).removeClass('hide') : $(`#org-email-check-${event.target.id}-container`).addClass('hide')
  });

  $('.org-epr-email-check').on("change", function(event){
    event.target.checked ? $(`#org-epr-email-check-${event.target.id}-container`).removeClass('hide') : $(`#org-epr-email-check-${event.target.id}-container`).addClass('hide')
  });

  $("#email-input").on("change", function() {
    var emailInput = $("#email-input").val();
    var emails = emailInput.split(",").map(function(email) {
      return email.trim();
    });

    var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    var validEmails = [];
    var invalidEmails = [];

    for (var i = 0; i < emails.length; i++) {
      if (emailRegex.test(emails[i])) {
        validEmails.push(emails[i]);
      } else {
        invalidEmails.push(emails[i]);
      }
    }

    if (invalidEmails.length > 0) {
      var invalidEmailsStr = invalidEmails.join(", ");
      $("#user_notification_emails-error").text("Invalid email address(es): " + invalidEmailsStr).removeClass("hide");
    } else {
      $("#user_notification_emails-error").addClass("hide");
    }
  });

  $("#invoice_status").on("change", function() {
    if ($(this).val() === "Approved" || $(this).val() === "") {
      $(".submit_button").show();
      $(".rejection-modal-btn").hide();
      $(".paid-modal-btn").hide();
    } else if ($(this).val() === "Rejected") {
      $(".rejection-modal-btn").show();
      $(".submit_button").hide();
      $(".paid-modal-btn").hide();
    } else if ($(this).val() === "Paid") {
      $(".paid-modal-btn").show();
      $(".rejection-modal-btn").hide();
      $(".submit_button").hide();
    }
  });

  $(".form_submit_button").click(function() {
    $(this).prop("disabled", true);
    $("form").submit();
  });

  $(".role_permission_dropdown").change(function() {
    window.location.replace("/permissions?role_name=" + this.value);
  })

  $("#financing_rule_customer_dropdown").change(function() {
    window.location.replace("/financing_rules/new?customer_id=" + this.value);
  })

  $("#credit_limit_customer_dropdown").change(function() {
    window.location.replace("/credit_limits/new?customer_id=" + this.value);
  })

  $(".admin_role_permission_dropdown").change(function() {
    window.location.replace("/admin/permissions?role_name=" + this.value);
  })

  $(".select_customer").change(function() {
    window.location.replace("/settings?customer_id=" + this.value);
  })


  $(".selectall-checkbox-customer").change(function() {
    $(".checkbox-customer").prop('checked', $(this).is(":checked"))
  })

  $(".selectall-checkbox-supplier").change(function() {
    $(".checkbox-supplier").prop('checked', $(this).is(":checked"))
  })

  $(".selectall-checkbox-financier").change(function() {
    $(".checkbox-financier").prop('checked', $(this).is(":checked"))
  })

  $(".country").change(function() {
    $.ajax({
      url: "/states?country=" + this.value,
      type: "GET"
    })
  })

  $("#items_count").change(function() {
    if (document.location.href.includes('?')) {
      var url = new URL(window.location.href);
      var params = new URLSearchParams(url.search);
      params.delete('items');

      if (params == "") {
        var url = location.pathname + "?" + "items=" + this.value;
      } else {
        var url = location.pathname + "?" + params + "&items=" + this.value;
      }

    } else {
      var url = window.location.href + "?items=" + this.value;
    }
    window.location = url;
  });


  $(".otp").on('keyup', function(e) {
    if (e.keyCode == 8) { /* backspace */
      $(this).prev('.otp').focus();
    } else {
      $(this).next('.otp').focus();
    }
  });


  $("#discount_rule_default").click((event) => {
    if (event.target.checked == true) {
      $(".supplier_drop_down").hide();
    } else {
      $(".supplier_drop_down").show();
    }
  });

  $(document).ready(function() {
    if ($("#discount_rule_default").prop("checked") == true) {
      $(".supplier_drop_down").hide();
    } else {
      $(".supplier_drop_down").show();
    }
  });

  $("#financing_rule_default").click((event) => {
    if (event.target.checked == true) {
      $(".financier_drop_down").hide();
    } else {
      $(".financier_drop_down").show();
    }
  });

  $(document).ready(function() {
    if ($('.advance_select').length > 0) {
      $('.form-control.dropdown').remove()
      $('.advance_select').select2({
        placeholder: "Search & Select an Option",
        dropdownCssClass: "bg-light",
        selectionCssClass: "form-control form-select"
      });
    }
  });

  $("#ship").click(function() {
    var button = $(this).val();
    if (button == 1) {
      $(this).val(0);
      var name = $('#purchase_order_address_attributes_bill_to_party_name').val();
      $('#purchase_order_address_attributes_ship_to_party_name').val(name);
      var address_line_one = $('#purchase_order_address_attributes_billing_address_line_one').val();
      $('#purchase_order_address_attributes_shipping_address_line_one').val(address_line_one);
      var address_line_two = $('#purchase_order_address_attributes_billing_address_line_two').val();
      $('#purchase_order_address_attributes_shipping_address_line_two').val(address_line_two);
      var country = $('#address_country').val();
      $('#purchase_order_address_attributes_shipping_country').val(country);
      var state = $('#address_state').val();
      $('#purchase_order_address_attributes_shipping_state').val(state);
      var city = $('#address_city').val();
      $('#purchase_order_address_attributes_shipping_city').val(city);
      var post_code = $('#purchase_order_address_attributes_billing_post_code').val();
      $('#purchase_order_address_attributes_shipping_post_code').val(post_code);


    } else {

      $('#purchase_order_address_attributes_ship_to_party_name').val("");
      $('#purchase_order_address_attributes_shipping_address_line_one').val("");
      $('#purchase_order_address_attributes_shipping_address_line_two').val("");
      $('#purchase_order_address_attributes_shipping_country').val("");
      $('#purchase_order_address_attributes_shipping_state').val("");
      $('#purchase_order_address_attributes_shipping_city').val("");
      $('#purchase_order_address_attributes_shipping_post_code').val("");
      $(this).val(1);
    }

  });


  $("#invoice_shipping").click(function() {
    var button = $(this).val();
    if (button == 1) {
      $(this).val(0);
      var name = $('#invoice_address_attributes_bill_to_party_name').val();
      $('#invoice_address_attributes_ship_to_party_name').val(name);
      var address_line_one = $('#invoice_address_attributes_billing_address_line_one').val();
      $('#invoice_address_attributes_shipping_address_line_one').val(address_line_one);
      var address_line_two = $('#invoice_address_attributes_billing_address_line_two').val();
      $('#invoice_address_attributes_shipping_address_line_two').val(address_line_two);
      var country = $('#invoice_address_attributes_billing_country').find(":selected").text();
      $('#invoice_address_attributes_shipping_country').val(country);
      var state = $('#invoice_address_attributes_billing_state').find(":selected").text();
      $('#invoice_address_attributes_shipping_state').val(state);
      var city = $('#invoice_address_attributes_billing_city').find(":selected").text();
      $('#invoice_address_attributes_shipping_city').val(city);
      var post_code = $('#invoice_address_attributes_billing_post_code').val();
      $('#invoice_address_attributes_shipping_post_code').val(post_code);


    } else {

      $('#invoice_address_attributes_ship_to_party_name').val("");
      $('#invoice_address_attributes_shipping_address_line_one').val("");
      $('#invoice_address_attributes_shipping_address_line_two').val("");
      $('#invoice_address_attributes_shipping_country').val("");
      $('#invoice_address_attributes_shipping_state').val("");
      $('#invoice_address_attributes_shipping_city').val("");
      $('#invoice_address_attributes_shipping_post_code').val("");
      $(this).val(1);
    }

  });


  $("#financing_rule_customer_id").on("change", function() {
    var customer_id = $(this).val();
    $.ajax({
      url: "/financing_rules/show",
      method: "GET",
      dataType: "json",
      data: {
        customer_id: customer_id
      },
      error: function(xhr, status, error) {},
      success: function(response) {
        var customer_suppliers = response
        $("#financing_rule_supplier_id").empty();
        $("#financing_rule_supplier_id").append('<option>Select Supplier</option>');
        for (var i = 0; i < customer_suppliers.length; i++) {
          $("#financing_rule_supplier_id").append('<option value="' + customer_suppliers[i]["id"] + '">' + customer_suppliers[i]["name"] + '</option>');
        }
      }
    });
  });

  var current_user = getCookie('current_user_role');
  $.fn.dataTable.ext.errMode = 'none';

  $('#admin_purchase_order_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,"language": {
      searchPlaceholder: "Search Purchase Order"
    },
    "ajax": $(location).attr('href'),
    
    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5
    }, {
      "targets": 6,
      "className": "hide"
    }, {
      "targets": 7,
      "orderable": false,
      "className": "text-center"
    }],
    "order": [[6, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp'
  });

  $(document).on('click', '.dropdown-toggle1', function() {
    $('.dropdown-menu').removeClass('show');
    $(this).siblings('.dropdown-menu').toggleClass('show');
  });
  
  $(document).on('click', function(event) {
    if (!$(event.target).closest('.dropdown').length) {
      $('.dropdown-menu').removeClass('show');
    }
  });

  $('#admin_quantity_units_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
     "language": {
      searchPlaceholder: "Search Quantity Unit"
    },
    "ajax":$(location).attr('href'),

    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp'
  });

  $('#admin_organisations_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
     "language": {
      searchPlaceholder: "Search Organisation"
    },
    "ajax":$(location).attr('href'),
    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp'
  });

  $('#admin_sites_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
     "language": {
      searchPlaceholder: "Search Site"
    },
    "ajax":$(location).attr('href'),
    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp'
  });

  $('#admin_delivery_order_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    "language": {
      searchPlaceholder: "Search Delivery Order"
    },
    "ajax": $(location).attr('href'),
    
    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5
    }, {
      "targets": 6
    }, {
      "targets": 7,
      "orderable": false,
      "className": "text-center"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });




  $("#admin_customers_table").DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    ajax: $(location).attr("href"),
    language: {
      searchPlaceholder: "Search Customer",
    },

    columnDefs: [
      {
        targets: 0,
        width: "10%",
      },
      {
        targets: 1,
      },
      {
        targets: 2,
      },
      {
        targets: 3,
      },
      {
        targets: 4,
        orderable: false,
        className: "text-center",
      },
      {
        targets: 5,
        orderable: false,
        className: "text-center",
      },
    ],
    buttons: [
      {
        extend: "csv",
        text: "",
        title: "Fauree : Export data into CSV",
        exportOptions: {
          columns: ":visible",
        },
        className: "btn text-center cstm-btn bi bi-filetype-csv",
      },
      {
        extend: "copy",
        text: "",
        className: "btn text-center cstm-btn bi bi-clipboard",
      },
    ],
    dom: "lfQtiBp",
  });

  $('#admin_children_table').DataTable({

    "responsive": true,
    "processing": true,
    "language": {
      searchPlaceholder: "Search Sub User"
    },
    "ajax": {
      url: $(location).attr('href'),
      dataSrc: ""
    },

    "columns": [{
      "data": "id"
    }, {
      "data": "roles[0].name"
    }, {
      "data": "name"
    }, {
      "data": "email"
    }, {
      "data": "status",
      "render": function(data, type, row, meta) {
        if (row.status == 'Active') {
          return data;
        } else {
          return data;
        }
      }
    }, {
      "data": "id",
      'render': function(data) {
        return '<a href="' + $(location).attr('href') + "/" + data + '"><i class="bi bi-eye-fill" title="View"></i></a>';
      }
    }],


    "columnDefs": [{
      "targets": 0,
      "width": "10%"
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4,
      "orderable": false,
      "className": "text-center"
    }, {
      "targets": 5,
      "orderable": false,
      "className": "text-center"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });




  $('#admin_suppliers_table').DataTable({

    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    "language": {
      searchPlaceholder: "Search Supplier"
    },
    "ajax": $(location).attr('href'),

    "columnDefs": [{
      "targets": 0,
      "width": "10%"
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4,
      "orderable": false,
      "className": "text-center"
    }, {
      "targets": 5,
      "orderable": false,
      "className": "text-center"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });

  function getCookie(name) {
    function escape(s) {
      return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1');
    }
    var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
    return match ? match[1] : null;
  }

  $('#admin_financier_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    ajax: $(location).attr("href"),
    "language": {
      searchPlaceholder: "Search Financier"
    },

    "columnDefs": [{
      "targets": 0,
      "width": "10%"
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4,
      "orderable": false,
      "className": "text-center"
    }, {
      "targets": 5,
      "orderable": false,
      "className": "text-center"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });



  $('#admin_invoice_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    "language": {
      searchPlaceholder: "Search Invoice"
    },

    "ajax": $(location).attr('href'),

    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5
    }, {
      "targets": 6
    }, {
      "targets": 7
    }, {
      "targets": 8
    }, {
      "targets": 9
    }, {
      "targets": 10,
      "orderable": false,
      "className": "text-center"
    }, {
      "targets": 11,
      "className": "hide"
    }],
    "order": [[11, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });

  $('#admin_early_payment_requests_table').DataTable({

    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    "language": {
      searchPlaceholder: "Search EPR"
    },

    "ajax": $(location).attr('href'),

    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5
    }, {
      "targets": 6
    }, {
      "targets": 7
    }, {
      "targets": 8
    }, {
      "targets": 9
    }, {
      "targets": 10,
      "orderable": false,
      "className": "text-center"
    }, {
      "targets": 11,
      "className": "hide"
    }],
    "order": [[11, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });

  var deliveryOrderTable = $('#delivery_orders_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search Delivery Order"
    },
    "columnDefs": [{
      "targets": 0,
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5
    }, {
      "targets": 6
    }, {
      "targets": 7,
      "orderable": false,
      "className": "text-center"
    },{
      "targets": 8
    }],
    "order": [[8, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv',
      action: async function (e, dt, button, config) {
        if (await isThisActionAllowedToCurrentUser('delivery_orders', 'excel_download')) {
          $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, dt, button, config);
        } else {
          alert("You don't have permission. Please contact your organisation admin or support")
        }
      }
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard',
      action: async function (e, dt, button, config) {
        if (await isThisActionAllowedToCurrentUser('delivery_orders', 'clipboard_copy')) {
          $.fn.dataTable.ext.buttons.copyHtml5.action.call(this, e, dt, button, config);
        } else {
          alert("You don't have permission. Please contact your organisation admin or support")
        }
      }
    }],
    "dom": 'lfQtiBp',
  });
  // Check for URL parameters and apply them to DataTable
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      deliveryOrderTable.search(urlParams.get('search')).draw();
  }
  if (urlParams.has('order')) {
      // Apply order parameter
      var orderParams = urlParams.get('order').split(',');
      deliveryOrderTable.order(orderParams[0], orderParams[1]).draw();
  }

  // Save state to URL when state changes
  deliveryOrderTable.on('stateSaveParams.dt', function(e, settings, data) {
      var search = deliveryOrderTable.search();
      var order = deliveryOrderTable.order();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }
      if (order) {
          queryParams.push('order=' + order.join(','));
      }
      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });


  var invTable  = $('#invoices_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search Invoice"
    },
    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5
    }, {
      "targets": 6
    }, {
      "targets": 7
    }, {
      "targets": 8
    }, {
      "targets": 9
    }, {
      "targets": 10
    }, {
      "targets": 11
    }, {
      "targets": 12
    }, {
      "targets": 13
    }, {
      "targets": 14
    }, {
      "targets": 15,
      "orderable": false,
      "className": "text-center"
    }, {
      "target": 16,
      "className": "hide"
    }],
    "order": [[16, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv',
      action: async function (e, dt, button, config) {
        if (await isThisActionAllowedToCurrentUser('invoices', 'excel_download')) {
          $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, dt, button, config);
        } else {
          alert("You don't have permission. Please contact your organisation admin or support")
        }
      }
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard',
      action: async function (e, dt, button, config) {
        if (await isThisActionAllowedToCurrentUser('invoices', 'clipboard_copy')) {
          $.fn.dataTable.ext.buttons.copyHtml5.action.call(this, e, dt, button, config);
        } else {
          alert("You don't have permission. Please contact your organisation admin or support")
        }
      }
    }],
    "dom": 'lfQtiBp',
  });
  // Check for URL parameters and apply them to DataTable
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      invTable.search(urlParams.get('search')).draw();
  }
  if (urlParams.has('order')) {
      // Apply order parameter
      var orderParams = urlParams.get('order').split(',');
      invTable.order(orderParams[0], orderParams[1]).draw();
  }

  // Save state to URL when state changes
  invTable.on('stateSaveParams.dt', function(e, settings, data) {
      var search = invTable.search();
      var order = invTable.order();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }
      if (order) {
          queryParams.push('order=' + order.join(','));
      }
      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });

  const financierInvoicesTable = $('#financier_invoices_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search Invoice"
    },
    "ajax":$(location).attr('href'),
    "columnDefs": [{
      "orderable": false,
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5
    }, {
      "targets": 6
    }, {
      "targets": 7
    }, {
      "targets": 8
    }, {
      "targets": 9
    }, {
      "targets": 10
    }, {
      "targets": 11
    }, {
      "targets": 12
    }, {
      "targets": 13
    }, {
      "targets": 14
    }, {
      "targets": 15,
      "orderable": false,
      "className": "text-center"
    },{
      "targets": 16,
      "className": "hide"
    }],
    "order": [[16, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv',
      action: async function (e, dt, button, config) {
        if (await isThisActionAllowedToCurrentUser('invoices', 'excel_download')) {
          $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, dt, button, config);
        } else {
          alert("You don't have permission. Please contact your organisation admin or support")
        }
      }
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard',
      action: async function (e, dt, button, config) {
        if (await isThisActionAllowedToCurrentUser('invoices', 'clipboard_copy')) {
          $.fn.dataTable.ext.buttons.copyHtml5.action.call(this, e, dt, button, config);
        } else {
          alert("You don't have permission. Please contact your organisation admin or support")
        }
      }
    }],
    "dom": 'lfQtiBp',
  });
  // Check for URL parameters and apply them to DataTable
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      financierInvoicesTable.search(urlParams.get('search')).draw();
  }
  if (urlParams.has('order')) {
      // Apply order parameter
      var orderParams = urlParams.get('order').split(',');
      financierInvoicesTable.order(orderParams[0], orderParams[1]).draw();
  }
  // Save state to URL when state changes
  financierInvoicesTable.on('stateSaveParams.dt', function(e, settings, data) {
      var search = financierInvoicesTable.search();
      var order = financierInvoicesTable.order();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }
      if (order) {
          queryParams.push('order=' + order.join(','));
      }
      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });

  const detailRows = [];
  function epr_table_inside_invoices(data) {
    var eprArray = data.filter(item => typeof item === 'object' && item)

    if (eprArray.length == 0) {
      return 'No Approved/Paid EPR'
    }

    var html = '<table class="child-table">';
    html += `<thead>
              <tr>
                <th class="text-center">EPR ID</th>
                <th class="text-center">Submit Date</th>
                <th class="text-center">EPR Charges</th>
                <th class="text-center">EPR Amount</th>
                <th class="text-center">Status</th>
                <th class="text-center">Action</th>
              </tr>
            </thead>`;
    html += '<tbody>';

    for (const i in eprArray) {
      html += `<tr>
                <td>${eprArray[i].epr.id}</td>
                <td>${eprArray[i].epr.submit_date}</td>
                <td>${eprArray[i].epr.epr_charges}</td>
                <td>${eprArray[i].epr.epr_amount}</td>
                <td>${eprArray[i].epr.status}</td>
                <td>${eprArray[i].epr.link}</td>
              </tr>`;
    }
    
    html += '</tbody>';
    html += '</table>';
    
    return html;
  }

  financierInvoicesTable.on('click', 'tbody i.dt-control', function () {
    let tr = event.target.closest('tr');
    let row = financierInvoicesTable.row(tr);
    let idx = detailRows.indexOf(tr.id);
 
    if (row.child.isShown()) {
        tr.classList.remove('details');
        event.target.classList.remove('bi-arrow-up-circle');
        event.target.classList.add('bi-arrow-down-circle');
        row.child.hide();
        detailRows.splice(idx, 1);
    }
    else {
        tr.classList.add('details');
        event.target.classList.remove('bi-arrow-down-circle');
        event.target.classList.add('bi-arrow-up-circle');
        row.child(epr_table_inside_invoices(row.data())).show();
        row.child()[0].classList.add('bg-light')
        if (idx === -1) {
            detailRows.push(tr.id);
        }
    }
  });

  financierInvoicesTable.on('draw', () => {
    detailRows.forEach((id, i) => {
      let el = document.querySelector('#' + id + ' i.dt-control');
      if (el) {
        el.dispatchEvent(new Event('click', { bubbles: true }));
      }
    });
  });

  $('#financier_invoices_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    "language": {
        searchPlaceholder: "Search Invoice"
    },
    "columnDefs": [
        // your column definitions here
    ],
    "order": [[12, "desc"]],
    "buttons": [
        // your button definitions here
    ],
    "dom": 'lfQtiBp',
    initComplete: function () {
        var table = this;
        table.api().columns().every(function () {
            var column = this;
            if (column.index() === 0 || column.index() === 1) {
                var select = $('<select><option value=""></option></select>')
                    .appendTo($(column.footer()).empty())
                    .on('change', function () {
                        var val = $.fn.dataTable.util.escapeRegex($(this).val());
                        column.search(val ? '^' + val + '$' : '', true, false).draw();
                    });

                column.data().unique().sort().each(function (d, j) {
                    select.append('<option value="' + d + '">' + d + '</option>');
                });
            }
          });
      }
  });

  var purchaseOrderTable = $('#purchase_orders_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search Purchase Order"
    },
    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5
    }, {
      "targets": 6,
      "orderable": false
    }, {
      "targets": 7,
      "className": "hide"
    }],
    "order": [[7, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv',
      action: async function (e, dt, button, config) {
        if (await isThisActionAllowedToCurrentUser('purchase_orders', 'excel_download')) {
          $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, dt, button, config);
        } else {
          alert("You don't have permission. Please contact your organisation admin or support")
        }
      }
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard',
      action: async function (e, dt, button, config) {
        if (await isThisActionAllowedToCurrentUser('purchase_orders', 'clipboard_copy')) {
          $.fn.dataTable.ext.buttons.copyHtml5.action.call(this, e, dt, button, config);
        } else {
          alert("You don't have permission. Please contact your organisation admin or support")
        }
      }
    }],
    "dom": 'lfQtiBp',
  });
  // Check for URL parameters and apply them to DataTable
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
    // Apply search parameter
    purchaseOrderTable.search(urlParams.get('search')).draw();
  }
  if (urlParams.has('order')) {
    // Apply order parameter
    var orderParams = urlParams.get('order').split(',');
    purchaseOrderTable.order(orderParams[0], orderParams[1]).draw();
  }
  // Save state to URL when state changes
  purchaseOrderTable.on('stateSaveParams.dt', function(e, settings, data) {
    var search = purchaseOrderTable.search();
    var order = purchaseOrderTable.order();
    var queryParams = [];
    if (search) {
        queryParams.push('search=' + search);
    }
    if (order) {
        queryParams.push('order=' + order.join(','));
    }
    var newUrl = window.location.pathname + '?' + queryParams.join('&');
    window.history.replaceState({}, '', newUrl);
  });

  var suppliersTable = $('#suppliers_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search Supplier"
    },

    "columnDefs": [{
      "targets": 0,
      "orderable": false,
      "className": "text-center"
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5
    }, {
      "targets": 6,
      "orderable": false,
      "className": "text-center"
    }, {
      "targets": 7,
      "orderable": false,
      "className": "text-center"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv',
      action: async function (e, dt, button, config) {
        if (await isThisActionAllowedToCurrentUser('suppliers', 'excel_download')) {
          $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, dt, button, config);
        } else {
          alert("You don't have permission. Please contact your organisation admin or support")
        }
      }
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard',
      action: async function (e, dt, button, config) {
        if (await isThisActionAllowedToCurrentUser('suppliers', 'clipboard_copy')) {
          $.fn.dataTable.ext.buttons.copyHtml5.action.call(this, e, dt, button, config);
        } else {
          alert("You don't have permission. Please contact your organisation admin or support")
        }
      }
    }],
    "dom": 'lfQtiBp',
  });
  // Check for URL parameters and apply them to DataTable
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
    // Apply search parameter
    suppliersTable.search(urlParams.get('search')).draw();
  }
  if (urlParams.has('order')) {
    // Apply order parameter
    var orderParams = urlParams.get('order').split(',');
    suppliersTable.order(orderParams[0], orderParams[1]).draw();
  }
  // Save state to URL when state changes
  suppliersTable.on('stateSaveParams.dt', function(e, settings, data) {
    var search = suppliersTable.search();
    var order = suppliersTable.order();
    var queryParams = [];
    if (search) {
        queryParams.push('search=' + search);
    }
    if (order) {
        queryParams.push('order=' + order.join(','));
    }
    var newUrl = window.location.pathname + '?' + queryParams.join('&');
    window.history.replaceState({}, '', newUrl);
  });


  $('#inbound_request_logs_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    "language": {
      searchPlaceholder: "Search Log"
    },
    "ajax": $(location).attr('href'),
    "columnDefs": [{
      "targets": 0,
      "width": "5%",
    },{
      "targets": 1,
      "width": "5%",
    },{
      "targets": 2,
      "width": "5%",
    },{
      "targets": 3,
      "width": "5%",
    },{
      "targets": 4,
      "className": 'table-data',
      "width": "35%",
      "render": function(data, type, row, meta) {
          return '<div class="text-container">' + data + '</div>';
      }
    },{
      "targets": 5,
      "className": 'table-data',
      "width": "35%",
      "render": function(data, type, row, meta) {
          return '<div class="text-container">' + data + '</div>';
      }
    },{
      "targets": 6,
      "width": "10%"
    }],
    "order": [[1, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": 'Export',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": 'Copy',
      "title": 'Fauree : Copy data on Clipboard',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
    "createdRow": function(row, data, dataIndex) {
      // Add "show more" functionality
      $(row).find('.text-container').each(function() {
          var fullText = $(this).text();
          var truncatedText = fullText.substring(0, 100);
          $(this).data('fullText', fullText);
          $(this).data('truncated', true);
          $(this).html(truncatedText + '<span class="toggle-more">... <a href="#" class="show-more">Show more</a></span>');
      });
    }
  });

  // Toggle "show more" and "show less"
  $(document).on('click', '.show-more, .show-less', function(e) {
    e.preventDefault();
    var textContainer = $(this).closest('.text-container');
    var fullText = textContainer.data('fullText');
    
    if (textContainer.data('truncated')) {
        textContainer.html(fullText + ' <span class="toggle-more"><a href="#" class="show-less">Show less</a></span>');
        textContainer.data('truncated', false);
    } else {
        var truncatedText = fullText.substring(0, 100);
        textContainer.html(truncatedText + '... <span class="toggle-more"><a href="#" class="show-more">Show more</a></span>');
        textContainer.data('truncated', true);
    }
  });

  $('#outbound_request_logs_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    "language": {
      searchPlaceholder: "Search Logs"
    },
    "ajax": $(location).attr('href'),

    "columnDefs": [{
      "targets": 0,
      "width": "5%",
    }, {
      "targets": 1,
      "width": "5%",
    }, {
      "targets": 2,
      "width": "5%",
    }, {
      "targets": 3,
      "width": "5%",
    },{
      "targets": 4,
      "className": 'table-data',
      "width": "35%",
      "render": function(data, type, row, meta) {
          return '<div class="text-container">' + data + '</div>';
      }
    },{
      "targets": 5,
      "className": 'table-data',
      "width": "35%",
      "render": function(data, type, row, meta) {
          return '<div class="text-container">' + data + '</div>';
      }
    },{
      "targets": 6,
      "width": "10%"
    }],
    "order": [[1, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": 'Export',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": 'Copy',
      "title": 'Fauree : Copy data on Clipboard',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
    "createdRow": function(row, data, dataIndex) {
      // Add "show more" functionality
      $(row).find('.text-container').each(function() {
          var fullText = $(this).text();
          var truncatedText = fullText.substring(0, 100);
          $(this).data('fullText', fullText);
          $(this).data('truncated', true);
          $(this).html(truncatedText + '<span class="toggle-more">... <a href="#" class="show-more-link">Show more</a></span>');
      });
    }
  });

  // Toggle "show more" and "show less"
  $(document).on('click', '.show-more-link, .show-less-link', function(e) {
    e.preventDefault();
    var textContainer = $(this).closest('.text-container');
    var fullText = textContainer.data('fullText');
    
    if (textContainer.data('truncated')) {
        textContainer.html(fullText + ' <span class="toggle-more"><a href="#" class="show-less-link">Show less</a></span>');
        textContainer.data('truncated', false);
    } else {
        var truncatedText = fullText.substring(0, 100);
        textContainer.html(truncatedText + '... <span class="toggle-more"><a href="#" class="show-more-link">Show more</a></span>');
        textContainer.data('truncated', true);
    }
  });

  var customersTable = $('#customers_table').DataTable({
    "responsive": true,
    "processing": true,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search Customer"
    },
    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4,
      "orderable": false,
      "className": "text-center"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      customersTable.search(urlParams.get('search')).draw();
  }
  if (urlParams.has('order')) {
      // Apply order parameter
      var orderParams = urlParams.get('order').split(',');
      customersTable.order(orderParams[0], orderParams[1]).draw();
  }
  // Save state to URL when state changes
  customersTable.on('stateSaveParams.dt', function(e, settings, data) {
      var search = customersTable.search();
      var order = customersTable.order();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }
      if (order) {
          queryParams.push('order=' + order.join(','));
      }
      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });

  var eprdataTable = $('#early_payment_requests_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search EPR"
    },
    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5
    }, {
      "targets": 6
    }, {
      "targets": 7
    }, {
      "targets": 8,
      "orderable": false,
      "className": "text-center"
    }, {
      "targets": 9,
      "className": "hide"
    }],
    "order": [[9, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv',
      action: async function (e, dt, button, config) {
        if (await isThisActionAllowedToCurrentUser('early_payment_requests', 'excel_download')) {
          $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, dt, button, config);
        } else {
          alert("You don't have permission. Please contact your organisation admin or support")
        }
      }
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard',
      action: async function (e, dt, button, config) {
        if (await isThisActionAllowedToCurrentUser('early_payment_requests', 'clipboard_copy')) {
          $.fn.dataTable.ext.buttons.copyHtml5.action.call(this, e, dt, button, config);
        } else {
          alert("You don't have permission. Please contact your organisation admin or support")
        }
      }
    }],
    "dom": 'lfQtiBp',
  });
  // Check for URL parameters and apply them to DataTable
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      eprdataTable.search(urlParams.get('search')).draw();
  }
  if (urlParams.has('order')) {
      // Apply order parameter
      var orderParams = urlParams.get('order').split(',');
      eprdataTable.order(orderParams[0], orderParams[1]).draw();
  }
  // Save state to URL when state changes
  eprdataTable.on('stateSaveParams.dt', function(e, settings, data) {
      var search = eprdataTable.search();
      var order = eprdataTable.order();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }
      if (order) {
          queryParams.push('order=' + order.join(','));
      }
      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });

  var eprFindataTable = $('#financier_early_payment_requests_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search EPR"
    },
    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5
    }, {
      "targets": 6
    }, {
      "targets": 7
    }, {
      "targets": 8
    }, {
      "targets": 9,
      "orderable": false,
      "className": "text-center"
    }, {
      "targets": 10,
      "className": "hide"
    }],
    "order": [[10, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv',
      action: async function (e, dt, button, config) {
        if (await isThisActionAllowedToCurrentUser('early_payment_requests', 'excel_download')) {
          $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, dt, button, config);
        } else {
          alert("You don't have permission. Please contact your organisation admin or support")
        }
      }
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard',
      action: async function (e, dt, button, config) {
        if (await isThisActionAllowedToCurrentUser('early_payment_requests', 'clipboard_copy')) {
          $.fn.dataTable.ext.buttons.copyHtml5.action.call(this, e, dt, button, config);
        } else {
          alert("You don't have permission. Please contact your organisation admin or support")
        }
      }
    }],
    "dom": 'lfQtiBp',
  });
  // Check for URL parameters and apply them to DataTable
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      eprFindataTable.search(urlParams.get('search')).draw();
  }
  if (urlParams.has('order')) {
      // Apply order parameter
      var orderParams = urlParams.get('order').split(',');
      eprFindataTable.order(orderParams[0], orderParams[1]).draw();
  }
  // Save state to URL when state changes
  eprFindataTable.on('stateSaveParams.dt', function(e, settings, data) {
      var search = eprFindataTable.search();
      var order = eprFindataTable.order();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }
      if (order) {
          queryParams.push('order=' + order.join(','));
      }
      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });


  var discountRuleTable = $('#discount_rule_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search Discount Rule"
    },
    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1,
      "orderable": false
    }, {
      "targets": 2,
      "orderable": false
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5,
      "orderable": false,
      "className": 'text-center'
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });
    // Check for URL parameters and apply them to DataTable
    var urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('search')) {
        // Apply search parameter
        discountRuleTable.search(urlParams.get('search')).draw();
    }
    if (urlParams.has('order')) {
        // Apply order parameter
        var orderParams = urlParams.get('order').split(',');
        discountRuleTable.order(orderParams[0], orderParams[1]).draw();
    }
    // Save state to URL when state changes
    discountRuleTable.on('stateSaveParams.dt', function(e, settings, data) {
        var search = discountRuleTable.search();
        var order = discountRuleTable.order();
        var queryParams = [];
        if (search) {
            queryParams.push('search=' + search);
        }
        if (order) {
            queryParams.push('order=' + order.join(','));
        }
        var newUrl = window.location.pathname + '?' + queryParams.join('&');
        window.history.replaceState({}, '', newUrl);
    });


  $('#admin_discount_rule_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    "language": {
      searchPlaceholder: "Search Discount Rule"
    },
    "ajax": $(location).attr('href'),

    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5,
      "orderable": false,
      "className": "text-center"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });

  var financingRulesTable = $('#financing_rule_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search Financing Rule"
    },
    "ajax": $(location).attr('href'),

    "columnDefs": [{
      "targets": 0,
      "width": "10%",
      "className": "text-center"
    }, {
      "targets": 1,
      "orderable": false,
      "width": "15%",
      "className": "text-center"
    }, {
      "targets": 2,
      "orderable": false,
      "width": "20%",
      "className": "text-center"
    }, {
      "targets": 3,
      "orderable": false,
      "width": "15%",
      "className": "text-center"
    }, {
      "targets": 4,
      "className": "text-center"
    }, {
      "targets": 5,
      "width": "15%",
      "orderable": false,
      "className": "text-center"
    }, {
      "targets": 6,
      "className": "hide"
    }],
    "order": [[6, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv',
      action: async function (e, dt, button, config) {
        if (await isThisActionAllowedToCurrentUser('financing_rules', 'excel_download')) {
          $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, dt, button, config);
        } else {
          alert("You don't have permission. Please contact your organisation admin or support")
        }
      }
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard',
      action: async function (e, dt, button, config) {
        if (await isThisActionAllowedToCurrentUser('financing_rules', 'clipboard_copy')) {
          $.fn.dataTable.ext.buttons.copyHtml5.action.call(this, e, dt, button, config);
        } else {
          alert("You don't have permission. Please contact your organisation admin or support")
        }
      }
    }],
    "dom": 'lfQtiBp',
  });
  // Check for URL parameters and apply them to DataTable
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      financingRulesTable.search(urlParams.get('search')).draw();
  }
  if (urlParams.has('order')) {
      // Apply order parameter
      var orderParams = urlParams.get('order').split(',');
      financingRulesTable.order(orderParams[0], orderParams[1]).draw();
  }
  // Save state to URL when state changes
  financingRulesTable.on('stateSaveParams.dt', function(e, settings, data) {
      var search = financingRulesTable.search();
      var order = financingRulesTable.order();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }
      if (order) {
          queryParams.push('order=' + order.join(','));
      }
      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });

  $('#admin_financing_rule_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    "language": {
      searchPlaceholder: "Search Financing Rule"
    },
    "ajax": $(location).attr('href'),

    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5
    }, {
      "targets": 6,
      "orderable": false,
      "className": "text-center"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });

  var creditLimitTable = $('#credit_limit_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search Credit Limit"
    },
    "ajax": $(location).attr('href'),
    "columnDefs": [{
      "targets": 0,
      "width": "10%",
      "className": "text-center"
    }, {
      "targets": 1,
      "orderable": false,
      "width": "15%",
      "className": "text-center"
    }, {
      "targets": 2,
      "orderable": false,
      "width": "20%",
      "className": "text-center"
    }, {
      "targets": 3,
      "orderable": false,
      "width": "15%",
      "className": "text-center"
    }, {
      "targets": 4,
      "className": "text-center"
    }, {
      "targets": 5,
      "width": "15%",
      "orderable": false,
      "className": "text-center"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv',
      action: async function (e, dt, button, config) {
        if (await isThisActionAllowedToCurrentUser('credit_limits', 'excel_download')) {
          $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, dt, button, config);
        } else {
          alert("You don't have permission. Please contact your organisation admin or support")
        }
      }
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard',
      action: async function (e, dt, button, config) {
        if (await isThisActionAllowedToCurrentUser('credit_limits', 'clipboard_copy')) {
          $.fn.dataTable.ext.buttons.copyHtml5.action.call(this, e, dt, button, config);
        } else {
          alert("You don't have permission. Please contact your organisation admin or support")
        }
      }
    }],
    "dom": 'lfQtiBp',
  });
  // Check for URL parameters and apply them to DataTable
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      creditLimitTable.search(urlParams.get('search')).draw();
  }
  if (urlParams.has('order')) {
      // Apply order parameter
      var orderParams = urlParams.get('order').split(',');
      creditLimitTable.order(orderParams[0], orderParams[1]).draw();
  }
  // Save state to URL when state changes
  creditLimitTable.on('stateSaveParams.dt', function(e, settings, data) {
      var search = creditLimitTable.search();
      var order = creditLimitTable.order();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }
      if (order) {
          queryParams.push('order=' + order.join(','));
      }
      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });

  $('#admin_credit_limit_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    "language": {
      searchPlaceholder: "Search Credit Limit"
    },
    "ajax":$(location).attr('href'),
    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5
    }, {
      "targets": 6,
      "orderable": false,
      "className": "text-center"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });

  var usersTables = $('#manage_user_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search User"
    },
    "ajax": $(location).attr('href'),
    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4,
      "orderable": false
    }, {
      "targets": 5,
      "orderable": false,
      "className": "text-center"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });
  // Check for URL parameters and apply them to DataTable
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      usersTables.search(urlParams.get('search')).draw();
  }
  // Save state to URL when state changes
  usersTables.on('stateSaveParams.dt', function(e, settings, data) {
      var search = usersTables.search();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }

      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });

  var rolesTable = $('#role_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search Role"
    },
    "ajax": $(location).attr('href'),

    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2,
      "orderable": false
    }, {
      "targets": 3,
      "orderable": false,
      "className": "text-center"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });
  // Check for URL parameters and apply them to DataTable
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      rolesTable.search(urlParams.get('search')).draw();
  }
  // Save state to URL when state changes
  rolesTable.on('stateSaveParams.dt', function(e, settings, data) {
      var search = rolesTable.search();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }
      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });

  $('#inbound_request_logs_table_imports').DataTable({
    "responsive": true,
    "processing": true,
    "language": {
      searchPlaceholder: "Search Log"
    },

    "columnDefs": [{
      "targets": 0,
      "width": "5%"
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5,
      "width": "10%"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": 'Export',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": 'Copy',
      "title": 'Fauree : Copy data on Clipboard',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });

  $('#import_logs_table').DataTable({
    "responsive": true,
    "processing": true,
    "language": {
      searchPlaceholder: "Search Log"
    },

    "columnDefs": [{
      "targets": 0,
      "width": "10%"
    },{
      "targets": 1,
      "width": "30%",
      "orderable": false
    }, {
      "targets": 2,
      "width": "40%",
      "type": "date"
    }, {
      "targets": 3,
      "width": "10%",
      "orderable": false
    }, {
      "targets": 4,
      "width": "10%",
      "orderable": false
    }],
    "order": [[2, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": 'Export',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": 'Copy',
      "title": 'Fauree : Copy data on Clipboard',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });

  $('#banking_details_table').DataTable({
    "responsive": true,
    "processing": true,
    "language": {
      searchPlaceholder: "Search Banking Details"
    },

    "columnDefs": [{
      "targets": 0,
      "width": "5%"
    },{
      "targets": 1,
      "width": "15%"
    }, {
      "targets": 2,
      "width": "10%"
    }, {
      "targets": 3,
      "width": "15%"
    }, {
      "targets": 4,
      "width": "20%"
    }, {
      "targets": 5,
      "width": "15%"
    }, {
      "targets": 6,
      "width": "15%"
    }, {
      "targets": 7,
      "width": "5%",
      "orderable": false
    }],
    "order": [[0, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": 'Export',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": 'Copy',
      "title": 'Fauree : Copy data on Clipboard',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });


  $(".loading-show").on("click", function() {
    var file = $("#imports_file").val();
    var type = $("#imports_type").val();
    if(file.length != 0 && type.length != 0 ) {
      $("#imports").submit();
      $('#loading').show();
      $('.loading-show').prop('disabled', true);
    }
  });
})

function modifyEntity() {
  let $amountField = $(`#entity-total-amount`)
  let $amountFieldWithcurrency = $('#total-amount-with-currencey')
  let currentAmount = 0

  $(".item-fields .item-field-row").each(function () {
    var value = parseFloat($(this).find(".item-entity-total-amount").val());
    if(value <= 0){
      value += parseFloat($(this).find(".total-amount").val());
    }
    // console.log("total-amount", $(this).find(".total-amount").val())
    // console.log("value", value)
    let isDestroy = $(this).find(".isdestroy").val();
    if (!isNaN(value) && isDestroy !== "1") { currentAmount += value; }
    if(isDestroy === "1") {$(this).find(".additional-prices").hide();}
  });

  $(`.formula-modal .formula-modal-fields`).each(function(index, element) {
    const $container = $(element);
    const $inputs = $container.find("input");
    const destroy = $inputs.first().val();
    const formulaMethod = $container.find("select").first().val();
    const amount = parseFloat($inputs.eq(2).val());
    currentAmount += (destroy === 'false' && formulaMethod === '+') ? amount : ((destroy === 'false' && formulaMethod === '-') ? -amount : 0);
  });
  $amountField.val(currentAmount.toFixed(2));
  $amountFieldWithcurrency.text(currentAmount.toFixed(2));
}

function modifyItemEntity(event) {
  let event_id = event.target.id.split("-").pop();
  var tax_included = $(`#tax-included-${event_id}`).prop('checked');
  var tax_calculation = $(`#tax-calculation-${event_id}`).prop('checked');
  let entityAmount = 0;
  let quantity_val = $(`#quantity-${event_id}`).val()
  let price_val = $(`#price-${event_id}`).val()
  let quantity = quantity_val != "" ? quantity_val : 1
  let price = price_val != "" ? price_val : 1

  if(quantity_val == "" && price_val == ""){
    quantity = 0
    price = 0
  }
  
  let currentAmount =  parseFloat(quantity) * parseFloat(price)
  let total_amount = $(`#total-amount-${event_id}`);

  if (tax_included || tax_calculation){
    currentAmount = parseFloat(total_amount.val());
  }else{
    total_amount.val(parseFloat(currentAmount).toFixed(2));
  }

  $(`.item-formula-modal-fields-${event_id}`).each(function(index, element) {
    const $container = $(element);
    const $inputs = $container.find("input");
    const destroy = $inputs.first().val();
    const formulaMethod = $container.find("select").first().val();
    const amount = parseFloat($inputs.eq(2).val());
    console.log("formula method is ", formulaMethod)
    entityAmount += (destroy === 'false' && formulaMethod === '+') ? amount : ((destroy === 'false' && formulaMethod === '-') ? -amount : 0);
  });

  if(entityAmount){
    currentAmount += parseFloat(entityAmount.toFixed(2));
  }else {
    currentAmount = 0;
  }
  $(`#item-entity-total-amount-${event_id}`).val(parseFloat(currentAmount).toFixed(2));
}

$(".modify-entity").on("click", function(event) {
      modifyEntity()
});
$(document).on('click', '.modify-item-entity', function(event) {
  modifyItemEntity(event)
  modifyEntity();
});

$(document).on('keyup change', '.quantity-price, .price-quantity, .total-amount', function(event) {
  modifyItemEntity(event)
  modifyEntity();   
});

$(document).on('change', '.tax-included, .tax-calculation', function(event) {
  modifyItemEntity(event);
  modifyEntity();
})

$(document).on('click', '.item-removed-event', function(event) {
  setTimeout(function() {
    modifyEntity()
  }, 0);
});

$(".headers-event").on("click", function(event) {
  
  let $headerContainer = $(".headers-area").empty()
  $(".headers-modal .additional-headers").each(function(index,element) {
    let $fields = $(element)
    let title = $($fields.find(".header-title")).val()
    let isDestroy = $($fields.find(".is-destroy")).val()
    let $styledElement = $("<span></span>").addClass("styled-header").text(title);
    if(isDestroy === "false" && title.trim().length > 0){$headerContainer.append($styledElement);} 
  })
})

$(document).on('click', '.add_fields', function(event) {
  event.preventDefault()
  handleAddFields(event.target,event)
  select2();
  select2_select();
  });
  
function handleAddFields(link, e) {
  // Stop the function from executing if a link or event were not passed into the function.
  if (!link || !e) return
  // Prevent the browser from following the URL.
  e.preventDefault()
  // Save a unique timestamp to ensure the key of the associated array is unique.
  let time = new Date().getTime()
  // Save the data id attribute into a variable. This corresponds to `new_object.object_id`.
  let linkId = link.dataset.id
  // Create a new regular expression needed to find any instance of the `new_object.object_id` used in the fields data attribute if there's a value in `linkId`.
  let regexp = linkId ? new RegExp(linkId, 'g') : null
  // Replace all instances of the `new_object.object_id` with `time`, and save markup into a variable if there's a value in `regexp`.
  let newFields = regexp ? link.dataset.fields.replace(regexp, time) : null
  // Add the new markup to the form if there are fields to add.
  newFields ? link.insertAdjacentHTML('beforebegin', newFields) : null
}

  
$(document).on('click', '.remove_fields', function(event) {
  handleRemoveFields(event.target,event)
});

function handleRemoveFields(link, e) {
  // Stop the function from executing if a link or event were not passed into the function.

  if (!link || !e) return
  // Prevent the browser from following the URL.
  e.preventDefault()
  // Find the parent wrapper for the set of nested fields.
  let fieldParent = link.closest('.nested-fields')
  // If there is a parent wrapper, find the hidden delete field.
  let deleteField = fieldParent
    ? fieldParent.querySelector('input[type="hidden"]')
    : null
  // If there is a delete field, update the value to `1` and hide the corresponding nested fields.
  if (deleteField) {
    let additional_prices_amount_id = $("#"+fieldParent.id).find('.additional-prices-amount')[0].id;
    $("#"+additional_prices_amount_id).val("0");
    console.log("additional_prices_amount_id", additional_prices_amount_id)
    deleteField.value = 1
    fieldParent.style.display = 'none'
  }
}

$(document).on('click', '.item_remove_fields', function(event) {
  if ($('.item_remove_fields:visible').length > 1) {
    handleItemRemoveFields(event.target, event)
  } else {
    alert('Record should contain atleast 1 item')
  }
});

function handleItemRemoveFields(link, e) {
  // Stop the function from executing if a link or event were not passed into the function.

  if (!link || !e) return
  // Prevent the browser from following the URL.
  e.preventDefault()
  // Find the parent wrapper for the set of nested fields.
  let fieldParent = link.closest('.fields, .nested-fields')
  // If there is a parent wrapper, find the hidden delete field.
  let deleteField = fieldParent
    ? fieldParent.querySelector('input[type="hidden"]')
    : null
  // If there is a delete field, update the value to `1` and hide the corresponding nested fields.
  if (deleteField) {
    deleteField.value = 1
    fieldParent.style.display = 'none'
    let quantity = $("#"+fieldParent.id).find('.quantity-price')[0].id;
    let price = $("#"+fieldParent.id).find('.price-quantity')[0].id;
    let total_amount = $("#"+fieldParent.id).find('.total-amount')[0].id;
    $("#"+quantity).val("0");
    $("#"+price).val("0");
    $("#"+total_amount).val("0");

    let event_id = e.target.id.split("-").pop();

    $(`.item-formula-modal-fields-${event_id}`).each(function(index, element) {
      const $container = $(element);
      const $inputs = $container.find("input");
      var destroy = $inputs.first();
      let additional_prices_amount_id = $container.find('.additional-prices-amount')[0].id;
      $("#"+additional_prices_amount_id).val("0");
      destroy.value = 1
    });
  }
}

$(document).on('change', '#invoice-customer-select', function(event) {
  var customerId = $(this).val();
  var organizationSelect = $('#invoice-organisation-select');
  var siteSelect = $('#invoice-site-select');
  organizationSelect.empty();
  siteSelect.empty();
  organizationSelect.append($('<option>').text('Please select customer first').attr('value', ''));
  siteSelect.append($('<option>').text('Please select organisation first').attr('value', ''));
  $.ajax({
    url: '/admin/customer_organisations',
    type: 'GET',
    data: { customer_id: customerId },
    dataType: 'json',
    success: function(data) {
      organizationSelect.empty();
      organizationSelect.append($('<option>').text('Select Organisation').attr('value', ''));
      $.each(data, function(key, value) {
        organizationSelect.append($('<option>').text(`${value.name}, ${value.number}`).attr('value', value.id));
      });
    }
  });
});

$(document).on('change', '#invoice-organisation-select', function(event) {
  var organisationId = $(this).val();
  var siteSelect = $('#invoice-site-select');
  var supplierId = $('#invoice-site-select').attr('data-supplier-id')
  siteSelect.empty();
  siteSelect.append($('<option>').text('Please select organisation first').attr('value', ''));
  $.ajax({
    url: '/admin/supplier_sites',
    type: 'GET',
    data: { organisation_id: organisationId, supplier_id: supplierId },
    dataType: 'json',
    success: function(data) {
      siteSelect.empty();
      siteSelect.append($('<option>').text('Select Site').attr('value', ''));
      $.each(data, function(key, value) {
        siteSelect.append($('<option>').text(`${value.name}, ${value.number}`).attr('value', value.id));
      });
    }
  });
});

$(document).on('change', '#discount-rule-suppliers-select', function(event) {
  var supplierId = $(this).val();
  var siteSelect = $('#discount-rule-sites-select');
  siteSelect.empty();
  siteSelect.append($('<option>')
    .text('Select Site')
    .attr('value', '')  // Set an empty value for the prompt option
  );

  $.ajax({
    url: '/admin/supplier_sites',
    type: 'GET',
    data: { supplier_id: supplierId },
    dataType: 'json',
    success: function(data) {
      $.each(data, function(key, value) {
        siteSelect.append($('<option>').text(`${value.name}, ${value.number}`).attr('value', value.id));
      });
    }
  });
});

$(document).on('change', '.confirmation-before-change', function(event) {
  const changingState = $(this).prop('checked');

  if(!confirm("Are you sure you want to change this settings?")) {
    $(this).prop('checked', !changingState); // reverting the change if response is no
  }
});

$(document).on('change', '#sftp_check', function(event) {
  if ($(this).prop('checked')) {
    $("#sftp_box").removeClass("d-none");
  } else {
    $("#sftp_box").addClass("d-none");
  }
})

$(document).on('change', '#final_payment_amount_check', function(event) {
  if ($(this).prop('checked')) {
    $(".final_payment_amount_field").removeClass("d-none");
  } else {
    $(".final_payment_amount_field").addClass("d-none");
  }
})

$(document).on('change', '.supplier_check_box', function(event) {
  if ($('.supplier_check_box:checked').length > 0) {
    $("#status-change-buttons").removeClass("d-none");
  } else {
    $("#status-change-buttons").addClass("d-none");
  }
})

document.addEventListener("DOMContentLoaded", function() {
  const activeLink = document.querySelector(".nav-item .nav-link.active");
  if (activeLink && activeLink.closest(".collapse")) {
    activeLink.closest(".collapse").classList.add("show");
  }
});

$(document).on('change', '#select_all_suppliers', function(event) {
  if ($(this).prop('checked')) {
    $('.supplier_check_box').prop("checked", true);
    $("#status-change-buttons").removeClass("d-none");
  } else {
    $('.supplier_check_box').prop("checked", false);
    $("#status-change-buttons").addClass("d-none");
  }
})

$(document).ready(function () {
  $('#default-domain').click(function () {
    $('#configuration_domain_div').removeClass('d-none');
    $('#white_label_configuration_domain').val('fauree.com');
    $('#white_label_configuration_domain').prop('readonly', true);
  });

  $('#custom-domain').click(function () {
    $('#configuration_domain_div').removeClass('d-none');
    $('#white_label_configuration_domain').val('');
    $('#white_label_configuration_domain').prop("readonly", false);
  });
});

$(document).on('change', '#invoice-currency-field', function(event) {
  if ($('#invoice-currency-field').attr('value') != event.target.value) {
    $('#invoice-exchange-rate-field').removeClass('d-none')
  } else {
    $('#invoice-exchange-rate-field').addClass('d-none')
  }
})

$(document).on('change', '.invoice_amount', function(event) {
  let invoiceAmount = $('.invoice_amount')[0]["value"]
  if ($('#recalculate-epr').length > 0) {
    let url = $('#recalculate-epr')[0].href
    $('#recalculate-epr')[0].href = `${url}?invoice_amount=${invoiceAmount}`
  }

  let discountPercentage = $('.discount_percentage')[0]['value']
  let paymentDaysOne = $('.payment_days_one')[0]['value']
  let financingRulePercentage = $('.financing_percentage')[0] ? $('.financing_percentage')[0]['value'] : 0
  let totalAnnualDay = $('#annual_rate_days')[0] ? $('#annual_rate_days')[0]['value'] : 365

  let discountAmount = ((invoiceAmount * discountPercentage)/100).toFixed(2)
  let financingAmount = (((invoiceAmount - discountAmount) * (((paymentDaysOne/totalAnnualDay) * financingRulePercentage) / 100))).toFixed(2)
  let eprAmount = (((invoiceAmount - discountAmount) - financingAmount)).toFixed(2)

  $('.discount_amount')[0]["value"] = discountAmount
  if ($('.financing_percentage')[0]) $('.financing_amount')[0]["value"] = financingAmount
  $('.epr_amount')[0]["value"] = eprAmount
  $("#total_amount_value").text(eprAmount);
});

// $(document).on('click', '.invoice_amount', function(event) {

// });

function updateCharacterCount() {
  const textArea = document.getElementById('remarks-input');
  const counter = document.getElementById('remarks-counter');
  const maxLength = parseInt(textArea.getAttribute('maxlength'));
  const currentLength = textArea.value.length;

  counter.textContent = `${currentLength}/${maxLength}`;
}
// Initial update
updateCharacterCount()
const remarksTextArea = document.getElementById('remarks-input');
remarksTextArea.addEventListener('input', updateCharacterCount);

function updateCharacterCounts() {
  const textArea = document.getElementById('reason-input');
  const counter = document.getElementById('reason-counter');
  const maxLength = parseInt(textArea.getAttribute('maxlength'));
  const currentLength = textArea.value.length;

  counter.textContent = `${currentLength}/${maxLength}`;
}
// Initial update
updateCharacterCounts()
const reasonTextArea = document.getElementById('reason-input');
reasonTextArea.addEventListener('input', updateCharacterCounts);


